<template>
    <div>
        <router-link class="card text-dark text-decoration-none rounded-xl overflow-hidden"
                     v-observe-visibility="visibilityChanged"
                     v-bind:class="{ 'is-selected': isSelected }"
                     v-bind:to="`/reviews/${ id }`">
            <div class="card-body d-flex flex-nowrap p-0">
                <div class="color-bar"
                     v-bind:class="isApprovedColor">
                </div>
                <div class="flex-grow-1">
                    <div class="flex-grow-1 d-flex justify-content-between p-2">
                        <div class="d-flex flex-column justify-content-between">
                            <reviewer-tag class="mb-3"
                                          v-bind:is-loading="isLoading"
                                          v-bind:created-by="createdBy">
                            </reviewer-tag>
                            <p class="flex-grow-1 feedback mb-3">
                                {{ feedBack }}
                            </p>
                            <p class="font-weight-bold mb-3">
                                {{ createdAt }}
                            </p>
                        </div>
                        <review-ratings class="col-auto"
                                        v-bind:cp-ratio="cpRatio"
                                        v-bind:impression="impression"
                                        v-bind:interesting="interesting">
                        </review-ratings>
                    </div>
                    <div class="d-flex px-2 pt-2 pb-0">
                        <review-activity class="mr-2"
                                         v-bind:is-loading="isLoading"
                                         v-bind:activity="activity">
                        </review-activity>
                        <review-organizer class=""
                                          v-bind:is-loading="isLoading"
                                          v-bind:organizer="organizer">
                        </review-organizer>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { getUserById } from "../../workers/users.worker";
import { getActivityById } from "../../workers/activity.worker";
import format from "date-fns/format";
import { ObserveVisibility } from "vue-observe-visibility";
import ReviewActivity from "./ReviewItem/ReviewActivity";
export default {
    name: "ReviewItem",
    directives: {
        "observe-visibility": ObserveVisibility,
    },
    components: {
        ReviewActivity,
        ReviewerTag: () => import("./ReviewItem/ReviewerTag"),
        ReviewOrganizer: () => import("./ReviewItem/ReviewOrganizer"),
        ReviewRatings: () => import("./ReviewItem/ReviewRatings"),
    },
    props: {
        review: {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,
            isPeopleLoaded: false,
            isActivityLoaded: false,

            organizer: null,
            createdBy: null,
            activity: null,
        };
    },
    computed: {
        currentRegion () {
            return this.$route.params?.region ?? "HKG";
        },
        id () {
            return this.review?._id ?? "";
        },
        isSelected () {
            return this.id === this.$route.params.reviewId;
        },
        isApproved () {
            return this.review?.isApproved ?? null;
        },
        isApprovedColor () {
            return this.isApproved === true ?
                "bg-success" :
                this.isApproved === false ?
                    "bg-danger" :
                    "bg-secondary";
        },
        createdAt () {
            return this.review?.createdAt ?
                format(new Date(this.review.createdAt), "PPp") :
                "Not available";
        },
        createdById () {
            return this.review?.createdBy ?? "";
        },
        answers () {
            return this.review?.answers ?? {};
        },
        activityId () {
            return this.answers?.activityAttended ?? "";
        },
        feedBack () {
            return this.answers?.feedback || "No content";
        },
        organizerId () {
            return this.answers?.organizer ?? "";
        },
        impression () {
            return this.answers?.impression;
        },
        cpRatio () {
            return this.answers?.cpRatio;
        },
        interesting () {
            return this.answers?.interesting;
        },
    },
    methods: {
        visibilityChanged (isVisible) {
            if (isVisible && !this.isPeopleLoaded) {
                this.loadPeople();
            }
            if (isVisible && !this.isActivityLoaded) {
                this.loadActivity();
            }
        },
        async loadPeople () {
            try {
                this.isLoading = true;
                const [createdBy, organizer] = await Promise.all([
                    getUserById(this.createdById),
                    getUserById(this.organizerId)
                ]);
                this.createdBy = createdBy;
                this.organizer = organizer;
                this.isPeopleLoaded = true;
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async loadActivity () {
            try {
                this.isLoading = true;
                this.activity = await getActivityById(this.activityId);
                this.isActivityLoaded = true;
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.card {
    transition:
        transform 150ms ease-in-out,
        box-shadow 200ms ease-in-out;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08%);

    &:hover,
    &:active,
    &.is-selected {
        transform: scale(1.01);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
}

.color-bar {
    width: 10px;
}

.feedback {
    white-space: pre-line;
}
</style>
