<template>
    <div>
        <router-link class="card text-dark text-decoration-none rounded-xl shadow-sm"
                     v-bind:to="`/activities/${ id }`">
            <div class="card-body p-2 py-1">
                <div class="text-center"
                     v-if="isLoading">
                    <b-spinner></b-spinner>
                    <div>Loading...</div>
                </div>
                <template v-else>
                    <div class="activity-title font-weight-bold card-title mb-0">
                        {{ title }}
                    </div>
                    <p class="text-right mb-0">
                        <small>scheduled on <strong>{{ startDate }}</strong></small>
                    </p>
                </template>
            </div>
        </router-link>
        <p class="text-secondary text-center text-nowrap mb-1">
            <small>Activity⛵</small>
        </p>
    </div>
</template>

<script>
import format from "date-fns/format";
import { BSpinner, } from "bootstrap-vue";
export default {
    name: "ReviewActivity",
    components: {
        BSpinner,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        activity: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        currentRegion () {
            return this.$route.params?.region ?? "HKG";
        },
        id () {
            return this.activity?._id ?? "";
        },
        title () {
            return this.activity?.title ?? "";
        },
        startDate () {
            return this.activity?.startDate ?
                format(new Date(this.activity?.startDate), "PPp") :
                "N.A.";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.card {
    max-width: 320px;
}

.activity-title {
    height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
